@font-face {
  font-family: "DIN Next LT Pro 300";
  src: url("../fonts/66cca6f6-c31d-4bbf-a2a7-998a38ca2ed9.eot?&aef03fa46c3a#iefix");
  src: url("../fonts/66cca6f6-c31d-4bbf-a2a7-998a38ca2ed9.eot?&aef03fa46c3a#iefix") format("eot"), url("../fonts/e492fdb2-9629-4064-a3cd-1233d2a65d11.woff2?aef03fa46c3a") format("woff2"), url("../fonts/5a230e3e-b354-4bab-863d-9b460a28ddb3.woff?aef03fa46c3a") format("woff"), url("../fonts/8dc3bf1f-1bc6-4fcc-9f28-cd3c3f6d13fd.ttf?aef03fa46c3a") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "DIN Next LT Pro";
  src: url("../fonts/49246907-8813-4cfe-84dc-28103a57b07f.eot?&aef03fa46c3a#iefix");
  src: url("../fonts/49246907-8813-4cfe-84dc-28103a57b07f.eot?&aef03fa46c3a#iefix") format("eot"), url("../fonts/8df454bc-0c75-47e7-8966-c7367f1710c8.woff2?aef03fa46c3a") format("woff2"), url("../fonts/52e2969a-646c-4c17-ba69-49112a741c97.woff?aef03fa46c3a") format("woff"), url("../fonts/6aa11345-10ac-45d0-a3e7-f4f3fb7fdc27.ttf?aef03fa46c3a") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DIN Next LT Pro 500";
  src: url("../fonts/923e62a3-4b49-4936-be63-4b4aac020c3d.eot?&aef03fa46c3a#iefix");
  src: url("../fonts/923e62a3-4b49-4936-be63-4b4aac020c3d.eot?&aef03fa46c3a#iefix") format("eot"), url("../fonts/d0906a1a-ec28-414d-887a-145c1fd3d589.woff2?aef03fa46c3a") format("woff2"), url("../fonts/f60b43f8-137d-4ea8-9e9e-d6b0c6cea0d3.woff?aef03fa46c3a") format("woff"), url("../fonts/52dbdc11-b6b1-484e-85e0-7c58dc187df3.ttf?aef03fa46c3a") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "DIN Next LT Pro 600";
  src: url("../fonts/3a88649e176a40a6d80b395ca0ae430d.eot?&aef03fa46c3a#iefix");
  src: url("../fonts/3a88649e176a40a6d80b395ca0ae430d.eot?&aef03fa46c3a#iefix") format("eot"), url("../fonts/3a88649e176a40a6d80b395ca0ae430d.woff2?aef03fa46c3a") format("woff2"), url("../fonts/3a88649e176a40a6d80b395ca0ae430d.woff?aef03fa46c3a") format("woff"), url("../fonts/3a88649e176a40a6d80b395ca0ae430d.ttf?aef03fa46c3a") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "DIN Next LT Pro 900";
  src: url("../fonts/daef1a0a-17b7-47ce-9b2a-4b3cd80db578.eot?&aef03fa46c3a#iefix");
  src: url("../fonts/daef1a0a-17b7-47ce-9b2a-4b3cd80db578.eot?&aef03fa46c3a#iefix") format("eot"), url("../fonts/9dc09d53-8913-40cf-8935-a7f059ad3b31.woff2?aef03fa46c3a") format("woff2"), url("../fonts/cb2a6b45-2c1b-427b-ada2-f72eb07822bc.woff?aef03fa46c3a") format("woff"), url("../fonts/62d3c949-5cc0-493a-8ce7-a8bdfcf25fbe.ttf?aef03fa46c3a") format("truetype");
  font-weight: 900;
  font-style: normal;
}
body {
  background-color: #fff;
  color: #333333;
  font-family: "DIN Next LT Pro", sans-serif;
  font-size: 16px;
  opacity: 1;
  font-variant-ligatures: none;
}
.page-wrapper {
  max-width: 1320px;
}
.page-wrapper-smaller {
  margin: 0 auto;
  max-width: 600px;
}
.page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 72px;
  font-family: "DIN Next LT Pro 500", sans-serif;
}
@media (max-width: 768px) {
  .page-title {
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    font-family: "DIN Next LT Pro 500", sans-serif;
    color: #333333;
  }
}
.page-sub-title {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 48px;
  font-family: 'DIN Next LT Pro', sans-serif;
}
@media (max-width: 768px) {
  .page-sub-title {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    font-family: "DIN Next LT Pro", sans-serif;
    color: #4A4A4A;
  }
}
.text-30-36-bold {
  font-style: normal;
  font-variant: normal;
  font-size: 30px;
  line-height: 30px;
  font-family: 'DIN Next LT Pro 900', sans-serif;
}
@media (max-width: 768px) {
  .text-30-36-bold {
    font-size: 28px;
    line-height: 34px;
  }
}
.text-30-36-medium {
  font-style: normal;
  font-variant: normal;
  font-size: 30px;
  line-height: 30px;
  font-family: 'DIN Next LT Pro 500', sans-serif;
}
@media (max-width: 768px) {
  .text-30-36-medium {
    font-size: 28px;
    line-height: 34px;
  }
}
.text-30-36 {
  font-style: normal;
  font-variant: normal;
  font-size: 30px;
  line-height: 30px;
  font-family: 'DIN Next LT Pro', sans-serif;
}
@media (max-width: 768px) {
  .text-30-36 {
    font-size: 28px;
    line-height: 34px;
  }
}
.page-sub-title-bold {
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 43px;
  font-family: 'DIN Next LT Pro 900', sans-serif;
}
@media (max-width: 768px) {
  .page-sub-title-bold {
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 34px;
    font-family: "DIN Next LT Pro 900", sans-serif;
    color: #333333;
    text-transform: uppercase;
  }
}
.fw-600 {
  font-weight: 550;
}
.page-description {
  color: #4a4a4a;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  font-family: 'DIN Next LT Pro', sans-serif;
}
@media (max-width: 768px) {
  .page-description {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    font-family: "DIN Next LT Pro", sans-serif;
    color: #4A4A4A;
  }
}
.collection-description {
  color: #4a4a4a;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 25px;
  font-family: 'DIN Next LT Pro', sans-serif;
}
.button-arrow {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  font-family: 'DIN Next LT Pro', sans-serif;
  text-transform: uppercase;
}
.button-arrow span:hover {
  text-decoration: underline;
}
.button-arrow-medium {
  color: #516F79;
  font-style: normal;
  font-variant: normal;
  font-size: 14px;
  line-height: 18px;
  font-family: 'DIN Next LT Pro 500', sans-serif;
  text-transform: uppercase;
}
.list-unstyled {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
  margin-bottom: 0px !important;
}
hr {
  border: 1px solid #DCE0E2;
}
.underline {
  text-decoration: underline;
}
.feedback-footer-text {
  font-family: 'DIN Next LT Pro', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
  color: #4A4A4A;
  text-decoration: none;
}
.feedback-footer-text a,
.feedback-footer-text a:hover {
  color: #4A4A4A;
}
@media (max-width: 768px) {
  .feedback-footer-text {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-family: 'DIN Next LT Pro', sans-serif;
    color: #4A4A4A;
    display: inline-block;
  }
}
.footer-text {
  font-family: 'DIN Next LT Pro', sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
  color: #4A4A4A;
  text-decoration: none;
}
.footer-text:hover {
  color: #4A4A4A;
}
@media (max-width: 768px) {
  .footer-text {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-family: 'DIN Next LT Pro', sans-serif;
    color: #4A4A4A;
    display: inline-block;
  }
  .social-media {
    margin-top: 15px;
  }
}
a {
  color: #fff;
}
a:hover {
  color: #9bcfdd;
  text-decoration: underline;
}
input[type="radio"] {
  cursor: pointer;
}
.gray {
  color: #868e9d;
}
.red {
  color: red;
}
.cursor-pointer {
  cursor: pointer;
}
.logo {
  max-width: 250px;
  margin-top: 35px;
}
.custom-button {
  font-family: "DIN Next LT Pro 500", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  color: #FFFFFF;
  text-transform: uppercase;
  background-color: #536F7A;
  border-radius: 4px;
  padding-top: 10px;
}
.custom-button:hover {
  background: #274A58;
  color: #fff;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(34, 34, 34, 0.3);
}
.custom-button:disabled {
  background-color: darkgray;
  border: 0;
}
.custom-button span {
  padding-left: 15px;
  line-height: 20px;
}
.svg-ticket {
  margin-top: -4px;
  padding-left: 2px;
}
.custom-textbox,
.custom-textbox:focus {
  background-color: #050814;
  color: #fff;
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.55);
}
.pdf-icon {
  margin-left: 0px;
  margin-top: -3px;
  width: 50px;
  height: 50px;
  background: transparent;
}
.pdf-button {
  display: inline-block;
  max-width: 300px;
}
/* Increase radio button's and checkbox size */
input[type='checkbox'],
input[type='radio'] {
  width: 20px;
  height: 20px;
}
.custom-checkbox {
  border-color: #536f7a;
}
.custom-checkbox:checked {
  border-color: #536f7a;
  background-color: #536f7a;
}
.isHidden {
  visibility: hidden;
}
.margin-fees {
  margin-left: 37px;
}
.checkout-radio {
  top: 5px;
  position: relative;
  margin-right: 15px;
}
.blank-button {
  color: #9bcfdd;
  background-color: transparent;
  border: 0;
}
.checkoutEventName {
  color: #ffc529;
}
.custom-grey {
  color: rgba(255, 255, 255, 0.5);
}
.min-height-slot-description {
  min-height: 70px;
}
.non-uppercase {
  text-transform: initial !important;
}
.delivery_icon {
  max-width: 55px;
  vertical-align: top;
  margin-top: 4px;
  padding-left: 17px;
  padding-right: 17px;
}
.delivery-description {
  margin-left: 55px !important;
}
.payment_icon {
  max-width: 60px;
  vertical-align: top;
  padding-right: 5px;
}
.payment-description {
  margin-left: 60px !important;
}
@media (max-width: 768px) {
  .min-height-slot-description {
    min-height: 20px;
  }
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.7rem;
  }
}
/* desktop, tablet */
@media (min-width: 768px) {
  .visible-mobile {
    display: none !important;
  }
}
/* Mobile */
@media (max-width: 768px) {
  .visible-desktop {
    display: none !important;
  }
}
.padding-left-20 {
  padding-left: 20px;
}
.black-color {
  color: #333333;
}
.dark-grey-color {
  color: #4A4A4A;
}
.text-50-55 {
  font-style: normal;
  font-variant: normal;
  font-size: 50px;
  line-height: 50px;
  opacity: 1;
  text-align: left;
}
@media (max-width: 768px) {
  .text-50-55 {
    font-size: 32px;
    line-height: 38px;
  }
}
.text-45-50 {
  font-style: normal;
  font-variant: normal;
  font-size: 45px;
  line-height: 50px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1;
  text-align: left;
}
@media (max-width: 768px) {
  .text-45-50 {
    font-size: 30px;
    line-height: 34px;
  }
}
.text-40-45 {
  font-style: normal;
  font-variant: normal;
  opacity: 1;
  font-size: 40px;
  line-height: 45px;
  font-family: 'DIN Next LT Pro', sans-serif;
  text-transform: uppercase;
  text-align: left;
}
@media (max-width: 768px) {
  .text-40-45 {
    font-size: 22px;
    line-height: 28px;
  }
}
.text-30-37 {
  font-style: normal;
  font-size: 30px;
  line-height: 37px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1;
}
@media (max-width: 768px) {
  .text-30-37 {
    font-size: 25px;
    line-height: 30px;
  }
}
.text-16-18 {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  font-size: 16px;
  line-height: 18px;
  align-items: center;
}
.text-21-25 {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  font-size: 21px;
  line-height: 25px;
}
@media (max-width: 768px) {
  .text-21-25 {
    font-size: 16px;
    line-height: 20px;
  }
}
.text-20-22 {
  font-size: 20px;
  line-height: 22px;
  font-family: "DIN Next LT Pro", sans-serif;
  font-style: normal;
  font-variant: normal;
  opacity: 1;
}
@media (max-width: 768px) {
  .text-20-22 {
    font-size: 20px;
    line-height: 22px;
  }
}
.text-18-21 {
  font-size: 18px;
  line-height: 21px;
  font-family: "DIN Next LT Pro", sans-serif;
  font-style: normal;
  font-variant: normal;
  opacity: 1;
}
@media (max-width: 768px) {
  .text-18-21 {
    font-size: 18px;
    line-height: 21px;
  }
}
.text-20-22-medium {
  font-size: 20px;
  line-height: 27px;
  font-family: "DIN Next LT Pro 500", sans-serif;
  font-style: normal;
  font-variant: normal;
  opacity: 1;
}
@media (max-width: 768px) {
  .text-20-22-medium {
    font-size: 20px;
    line-height: 22px;
  }
}
.text-16-20 {
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1;
}
@media (max-width: 768px) {
  .text-16-20 {
    font-size: 16px;
    line-height: 20px;
  }
}
.text-16-18 {
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1;
}
@media (max-width: 768px) {
  .text-16-18 {
    font-size: 16px;
    line-height: 18px;
  }
}
.text-22-27 {
  text-align: left;
  font: normal;
  opacity: 1;
  font-size: 22px;
  line-height: 27px;
  font-family: "DIN Next LT Pro", sans-serif;
}
.grey-button {
  font-family: "DIN Next LT Pro 500", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  color: #FFFFFF;
  text-transform: uppercase;
  background-color: #536F7A;
  border-radius: 4px;
  padding-top: 10px;
  opacity: 1;
  border: 2px solid #536F7A;
  height: auto;
}
@media (max-width: 768px) {
  .grey-button {
    font-size: 16px;
    line-height: 20px;
  }
}
.grey-button:hover {
  background: #FFFFFF;
  color: #536F7A;
  border: 2px solid #536F7A;
  text-decoration: none;
}
.grey-button:disabled {
  background-color: darkgray;
  border: 2px solid darkgray;
}
.transperant-grey-button {
  font-family: "DIN Next LT Pro 500", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  text-transform: uppercase;
  border-radius: 4px;
  padding-top: 10px;
  text-align: center;
  color: #536F7A;
  opacity: 1;
  border: 2px solid #536F7A;
}
@media (max-width: 768px) {
  .transperant-grey-button {
    height: 38px;
    font-size: 16px;
    line-height: 20px;
  }
}
.text-28-34 {
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 34px;
  font-family: "DIN Next LT Pro", sans-serif;
}
@media (max-width: 768px) {
  .text-28-34 {
    font-size: 18px;
    line-height: 23px;
  }
}
.transperant-grey-button:hover {
  background: #536F7A;
  color: white;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(34, 34, 34, 0.3);
}
.transperant-grey-button:disabled {
  background-color: darkgray;
  border: 0;
}
.transperant-grey-button span {
  padding-left: 15px;
  line-height: 20px;
}
#accordion-information-tariffe {
  margin-top: 0px;
}
@media (min-width: 768px) {
  #accordion-information-tariffe {
    margin-right: 18px;
    padding-right: 0 !important;
  }
}
@media (max-width: 768px) {
  #accordion-information-tariffe {
    margin-top: 0px;
  }
}
/* Accordions */
.accordion-item {
  margin-top: 8px;
  padding: 10px 45px 10px 45px;
}
.accordion-item-grey {
  background-color: #EFF2F3;
}
.accordion-item-grey .accordion-button {
  background-color: #EFF2F3;
}
@media (max-width: 768px) {
  .accordion-item {
    margin-top: 8px;
    padding: 0;
  }
}
.accordion-button {
  color: #69838b;
  display: flex;
  align-items: center;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 27px;
  font-family: 'DIN Next LT Pro 500', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
}
.accordion-button-text {
  margin-top: 3px;
  cursor: pointer;
}
.accordion-body {
  margin-left: 45px;
  color: black;
}
/* Accordions - remove focus effects */
.accordion-button:not(.collapsed) {
  color: #333;
  background-color: transparent;
  box-shadow: none;
}
.accordion-button:focus {
  box-shadow: none;
}
/* Accordions - borders */
.accordion-item:not(:first-of-type) {
  border-top: 1px solid #dee2e6;
}
.accordion-item:first-of-type,
.accordion-item:last-of-type {
  border-radius: 0;
}
/* Accordions - arrow */
.accordion-button::after {
  display: none;
  /* hide bootstrap arrow */
}
.accordion-arrow {
  margin-right: 35px;
  transition: transform 0.3s ease;
}
@media (max-width: 768px) {
  .accordion-arrow {
    margin-right: 10px;
    min-height: 24px;
    min-width: 24px;
  }
}
.accordion-button.collapsed .accordion-arrow {
  transform: rotate(0deg);
  /* Right arrow */
}
.accordion-button:not(.collapsed) .accordion-arrow {
  transform: rotate(90deg);
  /* Down arrow */
}
.accordion-button-exhibition {
  color: #333;
}
.div-container-blue {
  /* UI Properties */
  background: #EFF2F3 0% 0% no-repeat padding-box;
  opacity: 1;
}
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
@media (max-width: 768px) {
  .progress-container {
    margin-top: 80px;
  }
}
.progress-step {
  text-align: center;
  position: relative;
  flex: 1;
  padding: 0 10px;
}
.progress-step .image {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.progress-step.active .image {
  color: white;
}
.progress-step.completed .image {
  color: white;
}
.progress-step-number {
  margin-top: 5px;
  font-weight: bold;
  flex-wrap: wrap;
  text-align: center;
  font-family: 'DIN Next LT Pro 500', sans-serif;
  color: #69838B;
  opacity: 1;
  font-size: 18px;
  line-height: 20px;
}
.progress-step .line {
  position: absolute;
  top: 19px;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #e9ecef;
  z-index: -1;
}
.progress-step.active .line,
.progress-step.completed .line {
  background-color: #DCE0E2;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.grey-line {
  /* UI Properties */
  border: 1px solid #707070;
  opacity: 1;
}
.text-20-24 {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 768px) {
  .text-20-24 {
    font-size: 18px;
    line-height: 21px;
  }
}
.text-35-42 {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  font-size: 35px;
  line-height: 42px;
}
@media (max-width: 768px) {
  .text-35-42 {
    font-size: 25px;
    line-height: 30px;
  }
}
.a-common {
  color: #333333 !important;
}
.a-common:hover {
  color: #333333 !important;
}
#collapseFour .accordion-body p a {
  color: black !important;
}
#collapseOpeningTimes .accordion-body p a {
  color: black !important;
}
#shopOpeningTime {
  color: black !important;
}
.padding-left-10 {
  padding-left: 10px;
}
.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Aligns children to the end (right) */
  width: 100%;
}
.membership-title {
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  font-family: "DIN Next LT Pro 600", sans-serif;
  font-variant: normal;
  opacity: 1;
  color: #4A4A4A;
}
@media (max-width: 768px) {
  .membership-title {
    font-size: 20px;
    line-height: 22px;
  }
}
#accordion-memberships .accordion-button {
  color: #4A4A4A;
}
.price-label {
  width: 60px;
}
.sticky-wrapper {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 0;
  /* Adjust this value if you want to offset from the top */
  background: white;
  /* Background color to cover content beneath */
  z-index: 10;
  /* Ensures it stays above other content */
}
.margin-top-90 {
  margin-top: 90px;
}
.font-900 {
  font-family: "DIN Next LT Pro 900";
}
.font-600 {
  font-family: "DIN Next LT Pro 600";
}
.font-500 {
  font-family: "DIN Next LT Pro 500";
}
.font-300 {
  font-family: "DIN Next LT Pro 300";
}
.font-normal {
  font-family: "DIN Next LT Pro";
}
.padding-left-15 {
  padding-left: 15px;
}
.padding-right-15 {
  padding-right: 15px;
}
.padding-right-10 {
  padding-right: 10px;
}
.padding-bottom-15 {
  padding-bottom: 15px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.border-right-20 {
  border-right: 20px solid #fff;
}
.red-color {
  color: red;
}
.padding-25 {
  padding: 25px !important;
}
.padding-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
@media (min-width: 768px) {
  .padding-0 {
    padding: 0px !important;
  }
}
.margin-0 {
  margin: 0px !important;
}
.margin-0 {
  margin: 0px !important;
}
.margin-top-80 {
  margin: 80px !important;
}
.margin-top-55 {
  margin-top: 55px !important;
}
.margin-bottom-55 {
  margin-bottom: 55px !important;
}
.margin-top-30 {
  margin-top: 30px !important;
}
.green-color {
  color: darkgreen;
}
.background-yellow {
  background-color: yellow;
}
.anchor-no-hover:hover {
  color: #4A4A4A;
  /* Same color as base, change if needed */
  text-decoration: underline;
  /* Keep the underline on hover */
}
.padding-top-5 {
  padding-top: 5px;
}
@media (max-width: 768px) {
  /* Target the select element */
  .form-select {
    /* Reduce padding */
    padding-right: 20px;
    /* adjust this value as needed */
    /* Align the arrow to the right */
    background-position: calc(100% - 10px) center;
    /* adjust this for precise alignment */
    /* Reduce arrow size */
    background-size: 10px 10px;
    /* adjust the size of the arrow */
    /* Optional: Adjust font size */
    font-size: 15px;
  }
}
.display-none {
  display: none !important;
}