/* exhibitions */
.ticketing-selected-date {
  font-style: normal;
  font-size: 30px;
  line-height: 37px;
  margin-top: 10px;
  font-family: "DIN Next LT Pro 500", sans-serif;
  color: #333333;
  opacity: 1;
  padding-left: 20px;
}
.timeslot-standalone {
  background-color: #ededed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.ticketing-timeslots-container {
  font-family: "DIN Next LT Pro 500", sans-serif;
  background: rgba(74, 74, 74, 0.1);
}
.ticketing-timeslots-container-standalone {
  font-family: "DIN Next LT Pro 500", sans-serif;
  max-width: 420px;
}
.ticketing-timeslots-div {
  font-style: normal;
  font-size: 30px;
  line-height: 37px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1 !important;
}
.ticketing-timeslot-container-Selected {
  font-style: normal;
  font-size: 30px;
  line-height: 37px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1 !important;
  background-color: white;
  margin: 0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.ticketing-timeslot-container {
  font-style: normal;
  font-size: 30px;
  line-height: 37px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1 !important;
  background-color: transparent;
  padding-top: 1px;
  padding-left: 20px;
  padding-right: 20px;
}
.ticketing-timeslot-div {
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: "DIN Next LT Pro", sans-serif;
  opacity: 1 !important;
  background-color: transparent;
}
.timeslot-div-sub-title-bold {
  color: #333333;
  padding-left: 20px;
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  opacity: 1;
  font-size: 20px;
  line-height: 22px;
}
.timslot-time-header {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 15px;
}
.timeslot-last-time {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  padding-bottom: 10px;
}
.padding-left-right-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.timeslot-inner-text {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  font-size: 16px;
  line-height: 18px;
}
.timslot-time-header-selected {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #536F7A;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 15px;
}
.timeslot-inner-text-selected {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #536F7A;
  font-size: 16px;
  line-height: 18px;
}
.timeslot-inner-text-disabled {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  font-size: 16px;
  line-height: 18px;
}
.timslot-time-header-disabled {
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 15px;
}
.ticketing-disabled {
  opacity: 0.5 !important;
}
.ticketing-timeslot-container:hover {
  cursor: pointer;
}
.timeslot-div-sub-title-bold-mobile {
  color: #333333;
  padding-left: 30px;
  text-align: left;
  font-family: "DIN Next LT Pro", sans-serif;
  font: normal;
  color: #4A4A4A;
  opacity: 1;
  font-size: 25px;
  line-height: 30px;
}
.timeslots-mobile-container {
  background-color: #ECECEC;
}
.timeslots-mobile-close-btn {
  color: #262626;
  max-width: 100%;
  /* Make the image fit within the button */
  max-height: 100%;
  /* Ensure the image doesn�t exceed button dimensions */
  font-size: 25px;
  opacity: 1;
  /* Ensure visibility */
  border: none;
  /* Remove border */
  font-weight: bold;
  background: transparent;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  margin-left: auto;
}
.timeslots-mobile-close-btn img {
  width: 30px;
  height: 30px;
  display: block;
  /* Remove any inline spacing */
}
.modal-fullscreen {
  height: 100vh;
  /* Full viewport height */
  margin: 0;
}
.no-border {
  border: none;
}
.timeslots-guided-tour-button-container {
  padding-top: 15px;
  padding-bottom: 10px;
}
.guided-tour-text {
  text-align: left;
  font: medium;
  line-height: 21px;
  color: #536F7A;
  opacity: 1;
  font-family: "DIN Next LT Pro 500", sans-serif;
}
.selected-date-container {
  /* UI Properties */
  background: #EFF2F3 0% 0% no-repeat padding-box;
  opacity: 1;
}
.ticket-type-list {
  width: 100%;
  margin-right: -10px;
}
.ticket-type-list tr:nth-of-type(2) {
  background-color: #EFF2F3;
  /* Choose your desired color */
}
.ticket-type-list td {
  height: 57px;
}
.ticket-type-list td:first-child {
  width: 70%;
}
@media (max-width: 768px) {
  .ticket-type-list td:first-child {
    width: 60%;
  }
}
.ticket-type-list td:nth-child(2) {
  width: 10%;
}
.ticket-type-list td:nth-child(3) {
  width: 10%;
}
.ticket-type-list td:last-child {
  text-align: right;
  width: 10%;
}
@media (max-width: 768px) {
  .ticket-type-list td:last-child {
    width: 20%;
  }
}
.hinweis-icon {
  width: 58px;
  height: 58px;
  opacity: 1;
}
@media (max-width: 768px) {
  .hinweis-icon {
    width: 38px;
    height: 38px;
  }
}
.guided-tour-image {
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .guided-tour-image {
    height: 200px;
  }
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.pagination li {
  margin: 0 5px;
}
.pagination a {
  display: inline-block;
  text-decoration: none;
  font-size: 22px;
  /* Adjust font size */
  width: 42px;
  /* Adjust width as needed */
  text-align: center;
  line-height: 27px;
  /* Adjust line height to vertically center text */
}
@media (max-width: 768px) {
  .pagination a {
    font-size: 18px;
    line-height: 21px;
    width: 35px;
  }
}
.selected-paging-number {
  background-color: #536F7A;
  color: #FFFFFF;
  border: none;
  padding: 8px 12px;
  border-radius: 50%;
  text-align: center;
  width: 35px;
  text-decoration: none;
}
.un-selected-paging-number {
  background-color: transparent;
  color: black !important;
  border: none;
  border-radius: 50%;
  padding: 8px 12px;
  text-align: center;
  min-width: 35px;
  text-decoration: none;
}
.paging-arrow-image {
  width: 40px;
  height: 35px;
}
@media (max-width: 768px) {
  .paging-arrow-image {
    width: 35px;
    height: 30px;
  }
}
.hinWeis-description {
  word-wrap: break-word;
  /* Ensures long words break properly */
  hyphens: auto;
  /* Adds hyphenation support for better text wrapping */
}
#a_warning1 {
  color: #4A4A4A !important;
  /* Force anchor tag color to black */
}
.preview-span {
  background-color: yellow;
}