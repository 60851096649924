/* exhibitions */
.exhibition-dates {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
  font-family: 'DIN Next LT Pro', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
}
/* Mobile */
@media (max-width: 768px) {
  .exhibition-dates {
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    font-family: 'DIN Next LT Pro', sans-serif;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.exhibition-title {
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 45px;
  font-family: 'DIN Next LT Pro 900', sans-serif;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .exhibition-title {
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    font-family: 'DIN Next LT Pro 900', sans-serif;
    text-transform: uppercase;
  }
}
@media (max-width: 768px) {
  .exhibition-title-mobile {
    color: #333333;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    font-family: "DIN Next LT Pro 500", sans-serif;
    text-transform: uppercase;
  }
}
.event-description {
  padding-right: 5px;
  padding-left: 5px;
}
.presale-badge {
  position: absolute;
  top: -28px;
  right: -10px;
  color: #343333;
  background-color: #fca49f;
  padding: 0.5rem 1rem;
  /* Remove this padding */
  width: 120px;
  /* Set the width and height to the same value */
  height: 120px;
  border-radius: 50%;
  /* This ensures the element is circular */
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  /* Center the text within the circle */
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(8deg);
  /* Rotate the text 45 degrees */
}
.price-list {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  font-family: 'DIN Next LT Pro', sans-serif;
}
.price-list tr:nth-of-type(2) {
  background-color: #EFF2F3;
  /* Choose your desired color */
}
.price-list td {
  height: 45px;
  padding-right: 5px;
  padding-left: 5px;
}
.price-list td:first-child {
  width: 80%;
}
.price-list td:nth-child(2) {
  width: 10%;
}
.price-list td:last-child {
  text-align: right;
  width: 10%;
}
/* sammlung */
.collection-bg-image {
  background-image: url('../img/home/sammlung-background.jpg');
  background-size: cover;
  background-position: center;
  margin-top: 93px;
}
.collection-content > tr > td {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 50%;
}
.white-box {
  background-color: white;
  width: 1128px;
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
  margin: 40px;
}
.collection-subtitle {
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 45px;
  font-family: 'DIN Next LT Pro Bold', sans-serif;
  text-transform: uppercase;
  color: #333333;
  text-align: left;
}
/* information & tariffe */
.containter-information-tariffe {
  margin-top: 100px;
}
@media (max-width: 768px) {
  .containter-information-tariffe {
    margin-top: 44px;
  }
  .sammlung-title {
    margin-top: 39px;
  }
}
.information-tariffe-title {
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  font-family: 'DIN Next LT Pro 500', sans-serif;
  padding-left: 5px;
  padding-right: 5px;
}
/* carousel */
.carousel-offers {
  min-height: 422px;
}
.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border: 2px solid black;
  border-radius: 50%;
  /* Makes them circles */
  margin: 0 4px;
  /* Space between indicators */
}
.carousel-indicators .active {
  background-color: black;
  border-color: black;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}
.carousel-offers-museum-entry-mobile {
  min-height: 235px;
}
.carousel-offers-museum-entry {
  min-height: 540px;
  padding-right: 0;
  /* Remove any internal padding */
  margin-right: 18px;
}
.museum-entry-price-list-container {
  margin-bottom: 50px;
}
.min-width-100px {
  min-width: 100px;
}
.col-md-custom-2 {
  flex: 0 0 32%;
  /* 4/12 * 100 = 33.33% */
  max-width: 38%;
}
.col-md-custom-3 {
  max-width: 33%;
}
.img-exibition {
  height: auto;
  width: 100%;
}
.carousel-text-div {
  position: absolute;
  top: 65%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust position to truly center */
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Vertical centering */
  align-items: center;
  /* Horizontal centering */
  width: 80%;
  /* Adjust to your desired width */
  max-width: 100%;
  /* Ensure it doesn't overflow */
  padding: 10px;
  /* Add padding to ensure text doesn't touch edges */
  box-sizing: border-box;
  word-wrap: break-word;
}
@media (max-width: 768px) {
  .carousel-text-div {
    top: 40%;
  }
}
.carousel-text-p {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-wrap: break-word;
}
.carousel-button {
  font-family: "DIN Next LT Pro 500", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-style: normal;
  color: #333333;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  border: none;
  text-decoration: none;
}
@media (max-width: 768px) {
  .carousel-button {
    padding: 5px;
  }
}
#shopOpeningTime a {
  color: black !important;
}
.exhibition-column1 {
  padding-right: 0;
  /* Remove any internal padding */
  margin-right: 30px;
  /* Add margin to increase spacing between columns */
}
.exhibition-column2 {
  padding-right: 0;
  /* Remove any internal padding */
  padding-left: 0;
  /* Remove any internal padding */
  margin-left: 15px;
  /* Add margin to increase spacing between columns */
  margin-right: 15px;
  /* Add margin to increase spacing between columns */
}
.exhibition-column3 {
  padding-left: 0;
  /* Remove any internal padding */
  margin-left: 30px;
  /* Add margin to increase spacing between columns */
}
.exhibition-column:last-child {
  margin-right: 0;
  /* Ensure the last column has no margin on the right */
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-right-15 {
  margin-right: 15px;
  padding-right: 0 !important;
  /* Remove any internal padding */
}
.padding-right-0 {
  padding-right: 0;
  /* Remove any internal padding */
}
.margin-left-15 {
  margin-left: 15px;
  padding-left: 0;
  /* Remove any internal padding */
}
.padding-left-0 {
  padding-left: 0;
  /* Remove any internal padding */
}
.margin-left-30 {
  margin-left: 30px;
  padding-left: 0;
  /* Remove any internal padding */
}
.tariff-top-margin-desk {
  margin-top: 86px;
}
@media (max-width: 768px) {
  .tariff-top-margin-desk {
    margin-top: 52px;
  }
}
.sammlung-top-margin-desk {
  margin-top: 81px;
}
@media (max-width: 768px) {
  .sammlung-top-margin-desk {
    margin-top: 52px;
  }
}
.offers-top-margin-desk {
  margin-top: 104px;
}
@media (max-width: 768px) {
  .offers-top-margin-desk {
    margin-top: 52px;
  }
}
.exhibition-overlay-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 18px);
  background-color: #da291c;
  color: white;
  text-align: center;
  padding: 15px 0;
}
@media (max-width: 768px) {
  .exhibition-overlay-box {
    position: static;
    width: 100%;
    padding: 10px 0;
  }
}