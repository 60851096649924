.ticket-remove-btn {
  color: #262626;
  max-width: 100%;
  /* Make the image fit within the button */
  max-height: 100%;
  /* Ensure the image doesn�t exceed button dimensions */
  opacity: 1;
  /* Ensure visibility */
  border: none;
  /* Remove border */
  font-weight: bold;
  background: transparent;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}
.ticket-remove-btn img {
  width: 21px;
  height: 21px;
  display: block;
  /* Remove any inline spacing */
}
.delivery-type img {
  width: 50px;
  height: 50px;
}
@media (max-width: 768px) {
  .delivery-type img {
    width: 40px;
    height: 40px;
  }
}
.payment-type img {
  width: 72px;
  height: 54px;
}
@media (max-width: 768px) {
  .payment-type img {
    width: 49px;
    height: 37px;
  }
}