.react-calendar {
    width: 100%;
    max-width: 100%;
    background: transparent;
    /*border: 1px solid #9bcfdd;*/
    font-family: 'FilsonProRegular', Arial, sans-serif;
    line-height: 1.125em;
    padding: 0px 20px 20px 20px;
    font-size: large;
}

@media all and (max-width: 900px) {
    .react-calendar {
        font-size: x-large;
    }

    abbr[title] {
        font-size: large !important;
    }

    .react-calendar__tile {
        padding: 16px !important;
    }

    .address-field {
        text-align: left !important;
    }
}

.react-calendar__navigation__label__labelText::first-letter {
    text-transform: uppercase;
}

abbr[title] {
    text-decoration: none;
    font-size: large;
}

.bold-date {
    text-shadow: 0 0 6px rgba(255,255,255,.55);
    text-align: center;
    font: normal normal normal 21px/25px DIN Next LT Pro;
    letter-spacing: 0px;
    color: #4A4A4A !important;
    text-transform: uppercase;
    opacity: 1;
}

.disabled-date {
    color: #c0c0c0 !important;
    font-weight: 300 !important;
    pointer-events: none;
}

.react-calendar--doubleView {
    width: 700px;
}

    .react-calendar--doubleView .react-calendar__viewContainer {
        display: flex;
        margin: -0.5em;
    }

        .react-calendar--doubleView .react-calendar__viewContainer > * {
            width: 50%;
            margin: 0.5em;
        }

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

    .react-calendar button {
        margin: 0;
        border: 0;
        outline: none;
        border-bottom: 1px solid rgba(255,255,255,.2);
    }

        .react-calendar button:enabled:hover {
            cursor: pointer;
        }

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 15px;
}

    .react-calendar__navigation button {
        min-width: 44px;
        background: none;
        border: 0 !important;
    }

        .react-calendar__navigation button:disabled {
            background-color: #fff;
            color: #fff;
        }

        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus {
            background-color: #fff;
        }

.react-calendar__month-view__weekdays {
    text-align: center;
    font-weight: bold;
    font-size: 0.75em;
    border-bottom: 1px solid rgba(255,255,255,.2);
}

.react-calendar__month-view__weekdays__weekday {
    padding: 12px 12px 8px 12px;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    background: none;
    text-align: center;
    line-height: 16px;
    padding: 20px;
    position: relative;
}

    .react-calendar__tile:disabled {
        background-color: #fff;
    }

    .react-calendar__tile:enabled:focus {
        background-color: #536F7A;
        color: #FFFFFF;
    }

.react-calendar__tile--now {
    background: transparent;
}

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: #ffffa9;
    }

.react-calendar__tile--hasActive {
    background: #76baff;
}

    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
        background: #a9d4ff;
    }

.react-calendar__tile--active {
    background: #536F7A;
    color: #FFFFFF !important;
    padding: 10px
}


    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #536F7A;
        color: #FFFFFF !important;
    }

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.react-calendar__navigation__arrow {
    display: inline;
    color: #000;
    margin-top: 10px !important;
    font-size: 45px;
}

.react-calendar__navigation__label {
    font-weight: 900;
    color: grey;
    font-size: 29px;
    line-height: 37px;
    justify-content: start;
    padding: 12px 0px 4px 0px;
    text-transform: uppercase;
    pointer-events: none !important;
    text-align: center;
    font: normal normal 900 29px/35px DIN Next LT Pro;
    letter-spacing: 0px;
    color: #333333;
    text-transform: uppercase;
    opacity: 1;
}

.react-calendar__month-view__weekdays__weekday {
    background-color: transparent;
    text-align: center;
    letter-spacing: 0px;
    color: #536F7A;
    text-transform: uppercase;
    opacity: 1;
    font-style: normal;
    font-size: 21px;
    line-height: 35px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none !important;
}
