.language {
  color: #343333;
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 42px;
  font-family: "DIN Next LT Pro 600", sans-serif;
  cursor: pointer;
}
.navbar-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Centers both horizontally and vertically */
  color: #fff;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  font-family: 'DIN Next LT Pro 600', sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
  /* To prevent text wrapping */
}
.navbar-toggler {
  font-size: 30px;
  border: 0;
  margin-right: 5px;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.cart-quantity {
  color: #fff;
  margin-top: -24px;
  margin-left: -15px;
  color: #e4bf61;
  /*color: #fff; */
  /*  uncomment for circle */
  /*background-color: #ff0000; */
  /* uncomment for circle*/
  border-radius: 50%;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 2px;
  font-weight: bold;
}
/* desktop, tablet */
@media (min-width: 1024px) {
  .navbar {
    height: 73px;
  }
  .navbar-brand-img-mobile {
    display: none;
  }
  .offcanvas {
    width: 576px !important;
    /* side menu size */
  }
}
/* Mobile - Tablet */
@media (max-width: 1024px) {
  .navbar {
    height: 42px;
    padding-top: 0px;
  }
  .navbar-center {
    font-size: 28px;
    margin-top: 3px;
  }
  .offcanvas.offcanvas-end {
    width: 100%;
    height: 100%;
  }
  .navbar-brand-img {
    display: none;
  }
  .navbar-toggler {
    font-size: 25px;
  }
}
.btn-close:focus {
  box-shadow: none;
}
.accordion-nav-button-text {
  color: #4A4A4A;
  font-weight: bold;
  font-style: normal;
  font-size: 28px;
  line-height: 34px;
  font-family: "DIN Next LT Pro 900", sans-serif;
  cursor: pointer;
}
.accordion-nav-sub {
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
  font-family: "DIN Next LT Pro 600", sans-serif;
  color: #4A4A4A;
}
.accordion-nav-text {
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: "DIN Next LT Pro", sans-serif;
  color: #4A4A4A;
}
.accordion-item-nav {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  border: 0;
}
.accordion-button-nav,
.accordion-button-nav:focus {
  padding-bottom: 0;
  border-bottom: 1px solid #DCE0E2;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.accordion-item-nav:not(:first-of-type) {
  border-top: 0 !important;
}
.accordion-body-nav {
  margin-left: 0 !important;
}
/* Initial styles for the navbar container */
.navbar-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1030;
  /* Ensure it stays above other content */
  transition: top 0.3s ease-in-out;
}
/* Navbar is visible (default state) */
.navbar-visible {
  top: 0;
}
/* Navbar is hidden when scrolling down */
.navbar-hidden {
  top: -73px;
  /* Adjust this value according to your navbar height */
}
.padding-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
@media (min-width: 768px) {
  .padding-0 {
    padding: 0px !important;
  }
}
@media (min-width: 1024px) {
  .nav-title {
    margin-top: 6px;
  }
}
.nav-shop-basket {
  width: 35px;
  height: 30px;
}
@media (max-width: 1024px) {
  .nav-shop-basket {
    width: 30px;
    height: 25px;
    margin-left: 10px;
  }
}
.nav-shop-basket-container {
  margin-right: 8px;
}
@media (max-width: 1024px) {
  .nav-shop-basket-container {
    margin-right: 0px;
  }
}