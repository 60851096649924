#CookiebotBanner {
  background-color: #333333;
  padding: 1rem 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  bottom: 0;
}
#CookiebotBanner .h4 {
  font-size: 23.9999px;
  line-height: 1.33;
  text-transform: none;
  color: #333333;
  margin-bottom: 0.75em;
  font-family: "DIN Next LT Pro 500";
  font-weight: 500;
}
#CookiebotBanner p {
  margin-top: 1rem;
  font-size: 18px;
  line-height: 1.444;
  font-weight: 400;
}
#CookiebotBanner .cookiebot-toggler {
  margin-top: 1rem;
}
#CookiebotBanner .cookiebot-toggler a {
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
#CookiebotBanner .cookiebot-toggler svg {
  margin-left: 0.35em;
}
.cookies-necessary-button {
  display: none;
}
#CookiebotBanner .button-wired a {
  border: 2px solid white;
  line-height: 36px;
  font-family: "DIN Next LT Pro 500";
  font-weight: 500;
  margin-top: 1rem;
}
@media (min-width: 46.25em) {
  #CookiebotBanner .button-wired a {
    margin-top: 0rem;
  }
}
#CookiebotBanner .button-wired a:hover {
  background-color: #FFFFFF;
  color: #333333;
}
#CookiebotBanner input[type="checkbox"] {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  border: 1px solid #DCDCDC;
  margin-right: 10px;
  background: #FFFFFF;
}
#CookiebotBanner .is-active input[type="checkbox"].gray-scale {
  background: #FFFFFF url('../img/icon-checkbox-gray.svg') center center no-repeat;
}
#CookiebotBanner .is-active input[type="checkbox"][disabled] {
  opacity: 0.5;
}
#CookiebotBanner.is-expanded .cookie-toggler-arrow {
  transform: scaleY(-1);
}
#CookiebotBanner.is-expanded .cookiebot-buttons {
  display: none;
}
@media (min-width: 65.625em) and (orientation: landscape) {
  .limiter {
    max-width: 990px;
    padding-left: 0;
    padding-right: 0;
  }
}
.limiter {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}
@media (min-width: 46.25em) {
  #CookiebotBanner.is-expanded .limiter-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
@media (min-width: 65.625em) {
  #CookiebotBanner .sub-selections {
    display: flex;
    flex-flow: row nowrap;
  }
}
@media (min-width: 46.25em) {
  #CookiebotBanner .info-box {
    align-items: center;
  }
}
#CookiebotBanner .info-box {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
  padding: 1rem;
  transition: color linear 0.5s, background-color linear 0.5s, border-color linear 0.5s;
  background-color: #E4BF61;
}
#CookiebotBanner .info-box .info-box-icon {
  font-size: 18px;
  line-height: 1.55;
  margin-right: 10px;
  display: flex;
}
#CookiebotBanner .info-box svg path {
  fill: #333333;
}
#CookiebotBanner .info-box .info-box-text {
  color: #333333;
  font-size: 18px;
}
@media (min-width: 46.25em) {
  #CookiebotBanner .sub-selections .selection-item {
    margin-top: 0;
  }
}
#CookiebotBanner .sub-selections .selection-item {
  margin: 0.5rem 2rem 0 0;
  margin-top: 0.5rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
@media (min-width: 46.25em) {
  #CookiebotBanner .sub-accept {
    justify-content: flex-end;
  }
}
#CookiebotBanner .sub-accept {
  display: flex;
  flex-flow: row;
}
#CookiebotBanner .button-golden {
  background: #E4BF61;
  color: #333333 !important;
  margin-top: 1rem;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  font-family: "DIN Next LT Pro 500";
  font-weight: 500;
}
#CookiebotBanner .button-golden:hover {
  box-shadow: 0px 4px 4px rgba(34, 34, 34, 0.3);
  background-color: #C99212;
}
#CookiebotBanner :not(.button-golden) {
  color: #FFFFFF;
}
#CookiebotBanner .button-size-medium .button {
  height: 39px;
}
#CookiebotBanner .button-golden a {
  line-height: 40px;
}
#CookiebotBanner .button-golden .button {
  color: #333333 !important;
}
#CookiebotBanner .button {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  padding: 12px 30px;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#CookiebotBanner .cookiebot-buttons,
#CookiebotBanner .cookiebot-head,
#CookiebotBanner .cookiebot-toggler {
  text-align: center;
}
@media (min-width: 46.25em) {
  #CookiebotBanner .cookiebot-head {
    text-align: left;
  }
}
#CookiebotBanner .sub-accept {
  justify-content: center;
}
@media (min-width: 46.25em) {
  #CookiebotBanner .sub-accept {
    justify-content: flex-end;
  }
}
#CookiebotBanner .cookiebot-sub {
  display: none;
  /* Ensure it starts hidden */
}